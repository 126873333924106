import React, { Component, useContext  } from "react";
import { Link, useLocation } from "react-router-dom";

import {
  Container,
  AppBar,
  CircularProgress,
  Paper,
  List,
  ListItem,
  ListItemLink,
  ListItemText,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Tabs,
  Tab,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Toolbar,
  MenuItem,
  FormControl,
  Typography,
  Button,
  InputLabel,
  Hidden,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Grid,
  Select,
  ListItemIcon,
} from "@material-ui/core";
import {
  AccountCircle,
  Close,
  ExpandMore,
  ExitToApp,
  Dashboard,
  LocalAtm,
  Update,
  AccountBox,
  AccountBalanceWallet,
  AccountBalance,
  BusinessCenter,
  ExitToAppSharp,
  SupervisorAccount,
  LockOutlined,
} from "@material-ui/icons";

import hp from "services/hp";

class Page extends Component {
  state = {
    page: ""
  };

  componentDidMount() {
    const { location } = this.props;
    const locations = location.pathname.split("/");
    let page = "dashboard";
    switch (locations[2]) {
      case "portfolio":
        page = "portfolio";
        break;
      case "portfolio-thankyou":
        page = "portfolio";
        break;
      case "campaign-update":
        page = "update";
        break;
      case "profile":
        page = "profile";
        break;
      case "kb-wallet":
        page = "kb-wallet";
        break;
      case "bank-account":
        page = "bank";
        break;
      case "project":
        page = "project";
        break;
      case "referral":
        page = "referral";
        break;
      case "pin":
        page = "pin";
        break;
    }
    this.setState({ page });
  }

  render() {
    const { page } = this.state;

    return (
      <div id="dashboard-component">
        <List component="nav">
          <Link to="/dashboard">
            {/* <Container maxWidth="xl"> */}
            <ListItem button className={page == "dashboard" ? "hover" : ""}>
              <Grid container>
                <Grid item xs md={10}>
                  {/* <ListItemText primary="Dashboard" /> */}
                  <Typography>Dashboard</Typography>
                </Grid>
                <Grid item xs md={2} style={{ textAlign: "center" }}>
                  <Dashboard />
                </Grid>
              </Grid>
            </ListItem>
            {/* </Container> */}
          </Link>
          <Link to="/dashboard/portfolio">
            <ListItem button className={page == "portfolio" ? "hover" : ""}>
              <Grid container>
                <Grid item xs md={10}>
                  {/* <ListItemText primary="Portfolio" /> */}
                  <Typography>Portfolio</Typography>
                </Grid>
                <Grid item xs md={2} style={{ textAlign: "center" }}>
                  <LocalAtm />
                </Grid>
              </Grid>
              {/* <ListItemText primary="Portfolio" />
              <LocalAtm /> */}
            </ListItem>
          </Link>
          <Link to="/dashboard/campaign-update">
            <ListItem button className={page == "update" ? "hover" : ""}>
              <Grid container>
                <Grid item xs md={10}>
                  {/* <ListItemText primary="Campaign Updates" /> */}
                  <Typography>Campaign Updates</Typography>
                </Grid>
                <Grid item xs md={2} style={{ textAlign: "center" }}>
                  <Update />
                </Grid>
              </Grid>
              {/* <ListItemText primary="Campaign Updates" />
              <Update /> */}
            </ListItem>
          </Link>
          <Link to="/dashboard/profile">
            <ListItem button className={page == "profile" ? "hover" : ""}>
              <Grid container>
                <Grid item xs md={10}>
                  {/* <ListItemText primary="Profile" /> */}
                  <Typography>Profile</Typography>
                </Grid>
                <Grid item xs md={2} style={{ textAlign: "center" }}>
                  <AccountBox />
                </Grid>
              </Grid>
              {/* <ListItemText primary="Profile" />
              <AccountBox /> */}
            </ListItem>
          </Link>
          {hp.wallet_available() && (
            <Link to="/dashboard/kb-wallet">
              <ListItem button className={page == "kb-wallet" ? "hover" : ""}>
                <Grid container>
                  <Grid item xs md={10}>
                    {/* <ListItemText primary="KB-Wallet" /> */}
                    <Typography>KB-Wallet</Typography>
                  </Grid>
                  <Grid item xs md={2} style={{ textAlign: "center" }}>
                    <AccountBalanceWallet />
                  </Grid>
                </Grid>
                {/* <ListItemText primary="KB-Wallet" />
                <AccountBalanceWallet /> */}
              </ListItem>
            </Link>
          )}
          <Link to="/dashboard/bank-account">
            <ListItem button className={page == "bank" ? "hover" : ""}>
              <Grid container>
                <Grid item xs md={10}>
                  {/* <ListItemText primary="Bank Account" /> */}
                  <Typography>Bank Account</Typography>
                </Grid>
                <Grid item xs md={2} style={{ textAlign: "center" }}>
                  <AccountBalance />
                </Grid>
              </Grid>
              {/* <ListItemText primary="Bank Account" />
              <AccountBalance /> */}
            </ListItem>
          </Link>
          <Link to="/dashboard/project">
            <ListItem button className={page == "project" ? "hover" : ""}>
              <Grid container>
                <Grid item xs md={10}>
                  {/* <ListItemText primary="My Projects" /> */}
                  <Typography>My Projects</Typography>
                </Grid>
                <Grid item xs md={2} style={{ textAlign: "center" }}>
                  <BusinessCenter />
                </Grid>
              </Grid>
              {/* <ListItemText primary="My Projects" />
              <BusinessCenter /> */}
            </ListItem>
          </Link>
          <Link to="/dashboard/referral">
            <ListItem button className={page == "referral" ? "hover" : ""}>
              <Grid container>
                <Grid item xs md={10}>
                  <Typography>Referral</Typography>
                </Grid>
                <Grid item xs md={2} style={{ textAlign: "center" }}>
                  <SupervisorAccount />
                </Grid>
              </Grid>
            </ListItem>
          </Link>
          <Link to="/dashboard/pin">
            <ListItem button className={page == "pin" ? "hover" : ""}>
              <Grid container>
                <Grid item xs md={10}>
                  <Typography>PIN</Typography>
                </Grid>
                <Grid item xs md={2} style={{ textAlign: "center" }}>
                  <LockOutlined />
                </Grid>
              </Grid>
            </ListItem>
          </Link>
          <ListItem button onClick={() => document.querySelector('[data-action="logout"]').click()}>
            <Grid container>
              <Grid item xs md={10}>
                {/* <ListItemText primary="Logout" /> */}
                <Typography>Logout</Typography>
              </Grid>
              <Grid item xs md={2} style={{ textAlign: "center" }}>
                <ExitToAppSharp />
              </Grid>
            </Grid>
            {/* <ListItemText primary="Logout" />
            <ExitToApp /> */}
          </ListItem>
        </List>
      </div>
    );
  }
}

export default Page;
