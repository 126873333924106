import React, { Component } from "react";

import {
  Container,
  Snackbar,
  Typography,
  Button,
  TextField,
  Grid,
  Paper,
  FormControl,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  OutlinedInput,
  InputAdornment,
  IconButton,
  InputLabel,
} from "@material-ui/core";
import api from "services/api";
import hp from "services/hp";
import { Alert, AlertTitle } from "@material-ui/lab";
import { SendOutlined, Visibility, VisibilityOff } from "@material-ui/icons";
import Countdown from 'react-countdown';

class Page extends Component {
  constructor() {
    super();
    this.state = {
      count_date: Date.now(),
      timer_code: 0,
      loading: false,
      button_loading: false,
      user: null,
      current_pin: null,
      pin: null,
      pin_confirmation: null,
      openSuccessSnack: false,
      snackBarSeverity: 'success',
      snackText: "",
      errors: null,
      setpin: false,
      forgot_form: false,
      otp_sent: false,
      code_verification: null,
      openDialogVerification: false,
      showHidePsw: {
        pin: false,
        pin_confirmation: false,
        current_pin: false
      }
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.onInit()
  }

  onInit = async () => {
    this.setState({
      loading: true
    })

    await api.get(`user/pin`).then(res => {
      this.setState({
        user: res.data.user,
        setpin: false,
        loading: false
      })
    })
  }

  onCheckPin = async (current_pin) => {
    return await new Promise((resolve, reject) => { 
      api.post(`user/pin/check`, {pin: current_pin}).then(res => {
        return resolve(res.data)
      }).catch(err => {
        return reject(err)
      })
    })
  }

  onSave = async (e) => {
    e.preventDefault();

    const { current_pin, pin, pin_confirmation, user } = this.state;
    let action_uri = `user/pin/store`
    let formData = new FormData();
    let checkRes = null;

    this.setState({button_loading: true})

    if (user?.has_pin) {
      // do stuff to update the PIN in Here,
      action_uri = `user/pin/update`
      await api.post(`user/pin/check`, {pin: current_pin}).then(res => {
        let { data } = res;
        checkRes = data
      })

      if (checkRes.status === false) {
        this.setState({
          openSuccessSnack: true,
          snackBarSeverity: 'error',
          snackText: checkRes.message,
          errors: null,
          button_loading: false
        })

        return false;
      }
    }

    formData.append('pin', pin);
    formData.append('pin_confirmation', pin_confirmation);

    await api.post(action_uri, formData).then(res => {
      const {data} = res

      if (data.status) {
        this.setState({
          openSuccessSnack: true,
          snackBarSeverity: 'success',
          snackText: data.message,
          setpin: false,
          current_pin: null,
          pin: null,
          pin_confirmation: null,
          code_verification: null,
          button_loading: false,
          forgot_form: false
        })
        
        this.onInit()
      } else {        
        this.setState({
          openSuccessSnack: true,
          snackBarSeverity: 'error',
          snackText: data.message,
          errors: data.data,
          button_loading: false,
          code_verification: null,
        })
      }
    }).catch(err => {
      console.log(err);
      alert()
    })

    return false;
  };

  onCancel = () => {
    this.setState({
      button_loading: false,
      forgot_form: false,
      setpin: false,
      openSuccessSnack: false,
      snackBarSeverity: 'error',
      snackText: null,
      errors: null,
      current_pin: null,
      pin: null,
      pin_confirmation: null,
      code_verification: null,
      loading: false
    })
  }

  onSendCedeVerification = (e) => {
    e.preventDefault();
    this.setState({
      openDialogVerification: false,
      button_loading: true
    })

    if (!this.state.otp_sent) {
      api.get(`user/pin/send-code`).then(res => {
        if (res.data.status) {
          this.setState({
            count_date: Date.now(),
            timer_code: 12000,
            openDialogVerification: true,
            otp_sent: false
          })
        } else {
          this.setState({
            timer_code: 0,
            otp_sent: false,
          })
        }
      })
    } else {
      this.setState({
        count_date: Date.now(),
        timer_code: 12000,
        openDialogVerification: true
      })
    }

    return false
  }

  onCheckCodeAndUpdate = async () => {
    const { pin, pin_confirmation } = this.state;
    let formData = new FormData();

    await api.post(`user/pin/check-code`, {code: this.state.code_verification}).then(res => {
      const {data} = res

      if(data.status) {
        formData.append('pin', pin);
        formData.append('pin_confirmation', pin_confirmation);
        
        api.post(`user/pin/update`, formData).then(u_res => {
          const {data} = u_res
          
          if(data.status) {
            this.setState({
              openSuccessSnack: true,
              snackBarSeverity: 'success',
              snackText: data.message,
              setpin: false,
              current_pin: null,
              pin: null,
              pin_confirmation: null,
              code_verification: null,
              button_loading: false,
              openDialogVerification: false
            })
            
            this.onInit()
          } else {
            this.setState({
              openSuccessSnack: true,
              snackBarSeverity: 'error',
              snackText: data.message,
              errors: data.data,
              button_loading: false,
              otp_sent: true,
              openDialogVerification: false,
              forgot_form: true,
              code_verification: null,
            })
          }
        })
      } else {
        this.setState({
          openSuccessSnack: true,
          snackBarSeverity: 'error',
          snackText: data.message,
          errors: null,
          button_loading: false
        })
      }
    })
  }

  render() {
    const { current_pin, pin, pin_confirmation, openSuccessSnack, snackBarSeverity, snackText, errors, user, setpin, loading, forgot_form, button_loading, openDialogVerification, code_verification, timer_code, count_date, otp_sent, showHidePsw } = this.state;

    const renderer = ({ seconds, completed }) => {
      if (completed) {
        return <Button style={{marginTop: '25px'}} onClick={this.onSendCedeVerification}>
        Resend OTP Code &nbsp;
        <SendOutlined />
      </Button>;
      } else {
        return <h3>{seconds}</h3>;
      }
    };

    return (
      <div id="kb-wallet">
        <Container maxWidth="xl">
          <Grid container spacing={4} style={{justifyContent: 'center'}}>
            <Grid
              item
              xs={12}
              md={10}
              lg={8}
              style={{ padding: "0 auto" }}
              className=""
            >
              <Paper className="padding">
                {loading ? (
                  <center>
                    <div style={{padding: '80px 0'}}>
                      <CircularProgress />
                      <h3>Please Wait...</h3>
                    </div>
                  </center>
                ) : (
                  <>
                    <Grid container spacing={2} style={{justifyContent: 'center'}}>
                      <Grid item xs={12} md={8} lg={8}>
                        <div className="padding">
                          {user === null ? (
                            <>
                              {setpin === true ? (
                                <>
                                  <h2 align="center">Set Your Secure PIN</h2>
                                  <p align="center">Create a 6-digit PIN to protect your transactions and investments. This PIN will ensure only you can authorize actions on your account.</p>
                                </>
                              ) : (
                                <>
                                  <h2 align="center">Secure Your Transactions with a Personal PIN</h2>

                                  <p align="center">
                                    To ensure the security of your transactions and investments, we require you to set a Personal Identification Number (PIN). This PIN will be used to authenticate your transactions, providing an extra layer of protection for your account.
                                  </p>
                                  <p align="center" style={{marginBottom: '40px'}}>
                                    Please choose a unique 6-digit PIN that only you will know, and keep it safe. Do not share your PIN with anyone.
                                  </p>
                                  <center>
                                    <Button variant="contained" color="primary" onClick={() => this.setState({setpin: true})} style={{width: '250px'}}>SET PIN NOW</Button>
                                  </center>
                                </>
                              )}
                            </>
                          ):(
                            <>
                              {setpin === true ? (
                                <>
                                  <h2 align="center">Change Your PIN</h2>
                                  <p align="center">To maintain the security of your account, please create a new 6-digit PIN. Ensure it's unique and not shared with anyone.</p>
                                </>
                              ) : (
                                <>
                                  {forgot_form === true ? (
                                    <center>
                                      <h2 align="center">Atur Ulang PIN Anda</h2>
                                      <p align="center">Masukkan informasi yang diperlukan untuk verifikasi.</p>
                                    </center>
                                  ) : (
                                    <>
                                      <h2 align="center">Keep Your Account Secure: Update Your PIN Regularly</h2>

                                      <p align="center" style={{marginBottom: '40px'}}>
                                        For your ongoing security, we recommend changing your PIN periodically. Regularly updating your PIN helps protect your account from unauthorized access and ensures your transactions remain secure. Stay in control of your account—update your PIN now!
                                      </p>

                                      <center>
                                        <Button variant="contained" color="primary" onClick={() => this.setState({setpin: true})} style={{width: '250px'}}>CHANGE PIN NOW</Button>
                                        <br/>
                                        <p>Forgot your PIN? No worries! <a href="javascript:void(0);" onClick={() => this.setState({forgot_form: true})}>Click here</a> to reset.</p>
                                      </center>
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                    {setpin === true && (
                      <form onSubmit={this.onSave} style={{width: '100%'}}>
                        <Grid container spacing={2} style={{justifyContent: 'center'}}>
                          <Grid item xs={12} md={10} lg={8}>
                            {errors !== null && (
                              <>
                                {Object.entries(errors).map(([key, value]) => (
                                  <Alert severity="error" key={key}>
                                    <AlertTitle>{key.toUpperCase()}</AlertTitle>
                                    <ul>
                                      {value.map((elm, i) => (
                                        <li key={i}>{elm}</li>
                                      ))}
                                    </ul>
                                  </Alert>
                                ))}
                              </>
                            )}
                          </Grid>
                          
                              {user?.has_pin && (
                                <Grid item xs={12} md={10} lg={8}>
                                    <FormControl variant="outlined" fullWidth margin="normal" required>
                                        <InputLabel htmlFor="outlined-adornment-current-pin">
                                          Enter Your Current PIN
                                        </InputLabel>
                                        <OutlinedInput
                                          id="outlined-adornment-current-pin"
                                          name="current_pin"
                                          type={showHidePsw.current_pin ? "text" : "password"}
                                          value={current_pin}
                                          onChange={(e) => this.setState({ current_pin: e.target.value })}
                                          inputProps={{ maxLength: 6 }}
                                          endAdornment={
                                            <InputAdornment position="end">
                                              <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => this.setState({showHidePsw: {current_pin: !showHidePsw.current_pin}})}
                                                edge="end"
                                              >
                                                {showHidePsw.current_pin ? <Visibility /> : <VisibilityOff />}
                                              </IconButton>
                                            </InputAdornment>
                                          }
                                          labelWidth={70}
                                        />
                                    </FormControl>
                                </Grid>
                              )}
                              <Grid item xs={12} md={10} lg={8}>
                                <FormControl variant="outlined" fullWidth margin="normal" required>
                                    {/* <InputLabel>Nationality</InputLabel> */}
                                    {/* <TextField
                                      margin="normal"
                                      label={`Enter Your PIN`}
                                      type="password"
                                      fullWidth
                                      required
                                      value={pin}
                                      inputProps={{ maxLength: 6 }}
                                      onChange={(e) => this.setState({ pin: e.target.value })}
                                    /> */}
                                    <InputLabel htmlFor="outlined-adornment-pin">
                                      Enter Your PIN
                                    </InputLabel>
                                    <OutlinedInput
                                      id="outlined-adornment-pin"
                                      name="pin"
                                      type={showHidePsw.pin ? "text" : "password"}
                                      value={pin}
                                      onChange={(e) => this.setState({ pin: e.target.value })}
                                      inputProps={{ maxLength: 6 }}
                                      endAdornment={
                                        <InputAdornment position="end">
                                          <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => this.setState({
                                              showHidePsw: {
                                                pin: !showHidePsw.pin
                                              }
                                            })}
                                            edge="end"
                                          >
                                            {showHidePsw.pin ? <Visibility /> : <VisibilityOff />}
                                          </IconButton>
                                        </InputAdornment>
                                      }
                                      labelWidth={70}
                                    />
                                </FormControl>
                              </Grid>

                              <Grid item xs={12} md={10} lg={8}>
                                  <FormControl variant="outlined" fullWidth margin="normal" required>
                                      {/* <InputLabel>Nationality</InputLabel> */}
                                      {/* <TextField
                                          margin="normal"
                                          label={`PIN Confirmation`}
                                          type="password"
                                          fullWidth
                                          required
                                          inputProps={{ maxLength: 6 }}
                                          value={pin_confirmation}
                                          onChange={(e) => this.setState({ pin_confirmation: e.target.value })}
                                      /> */}
                                      <InputLabel htmlFor="outlined-adornment-pin-confirm">
                                        PIN Confirmation
                                      </InputLabel>
                                      <OutlinedInput
                                        id="outlined-adornment-pin-confirm"
                                        name="pin_confirmation"
                                        type={showHidePsw.pin_confirmation ? "text" : "password"}
                                        value={pin_confirmation}
                                        onChange={(e) => this.setState({ pin_confirmation: e.target.value })}
                                        inputProps={{ maxLength: 6 }}
                                        endAdornment={
                                          <InputAdornment position="end">
                                            <IconButton
                                              aria-label="toggle password visibility"
                                              onClick={() => this.setState({showHidePsw: {pin_confirmation: !showHidePsw.pin_confirmation}})}
                                              edge="end"
                                            >
                                              {showHidePsw.pin_confirmation ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                          </InputAdornment>
                                        }
                                        labelWidth={70}
                                      />
                                  </FormControl>
                              </Grid>
                              
                              <Grid item xs={12} md={10} lg={8} align="center">
                              {button_loading ? (
                                <Button
                                  margin="normal"
                                  variant="contained"
                                  color="primary"
                                  className="space"
                                  style={{width: '200px'}}
                                  disabled
                                >
                                  <CircularProgress color="default" size={25} style={{marginRight: '10px'}} />
                                  Submit
                                </Button>
                              ) : (
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    style={{width: '200px'}}
                                    disabled={button_loading}
                                >
                                    Submit
                                </Button>
                              )}

                                <Button
                                    type="button"
                                    variant="contained"
                                    size="large"
                                    style={{width: '100px', margin: '0 15px'}}
                                    onClick={this.onCancel}
                                    disabled={button_loading}
                                >
                                    Cancel
                                </Button>
                              </Grid>
                        </Grid>
                      </form>
                    )}

                    {forgot_form === true && (
                      <>
                        <form onSubmit={this.onSendCedeVerification} style={{width: '100%'}}>
                          <Grid container spacing={2} style={{justifyContent: 'center'}}>
                            <Grid item xs={12} md={10} lg={8}>
                              {errors !== null && (
                                  <Alert>
                                    {Object.keys(errors).forEach(key => (
                                      <>
                                        {errors[key].forEach(element => (
                                          <>
                                            {element}
                                          </>
                                        ))}
                                      </>
                                    ))}
                                  </Alert>
                                )}
                                <FormControl variant="outlined" fullWidth margin="normal" required>
                                    {/* <InputLabel>Nationality</InputLabel> */}
                                    <InputLabel htmlFor="outlined-adornment-pin">
                                      Enter Your PIN
                                    </InputLabel>
                                    <OutlinedInput
                                      id="outlined-adornment-pin"
                                      name="pin"
                                      type={showHidePsw.pin ? "text" : "password"}
                                      value={pin}
                                      onChange={(e) => this.setState({ pin: e.target.value })}
                                      inputProps={{ maxLength: 6 }}
                                      endAdornment={
                                        <InputAdornment position="end">
                                          <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => this.setState({
                                              showHidePsw: {
                                                pin: !showHidePsw.pin
                                              }
                                            })}
                                            edge="end"
                                          >
                                            {showHidePsw.pin ? <Visibility /> : <VisibilityOff />}
                                          </IconButton>
                                        </InputAdornment>
                                      }
                                      labelWidth={70}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={10} lg={8}>
                              <FormControl variant="outlined" fullWidth margin="normal" required>
                                  <InputLabel htmlFor="outlined-adornment-pin-confirm">
                                    PIN Confirmation
                                  </InputLabel>
                                  <OutlinedInput
                                    id="outlined-adornment-pin-confirm"
                                    name="pin_confirmation"
                                    type={showHidePsw.pin_confirmation ? "text" : "password"}
                                    value={pin_confirmation}
                                    onChange={(e) => this.setState({ pin_confirmation: e.target.value })}
                                    inputProps={{ maxLength: 6 }}
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={() => this.setState({showHidePsw: {pin_confirmation: !showHidePsw.pin_confirmation}})}
                                          edge="end"
                                        >
                                          {showHidePsw.pin_confirmation ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                      </InputAdornment>
                                    }
                                    labelWidth={70}
                                  />
                              </FormControl>
                            </Grid>
                            
                            <Grid item xs={12} md={10} lg={8} align="center">
                              {button_loading ? (
                                <Button
                                  margin="normal"
                                  variant="contained"
                                  color="primary"
                                  className="space"
                                  style={{width: '200px'}}
                                  disabled
                                >
                                  <CircularProgress color="default" size={25} style={{marginRight: '10px'}} />
                                  Submit
                                </Button>
                              ) : (
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    style={{width: '200px'}}
                                >
                                    Submit
                                </Button>
                              )}

                                <Button
                                    type="button"
                                    variant="contained"
                                    size="large"
                                    style={{width: '100px', margin: '0 15px'}}
                                    onClick={this.onCancel}
                                    disabled={button_loading}
                                >
                                    Cancel
                                </Button>
                              </Grid>
                          </Grid>
                        </form>
                      </>
                    )}
                  </>
                )}
              </Paper>
            </Grid>
          </Grid>
        </Container>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={openSuccessSnack}
          autoHideDuration={6000}
          onClose={() => this.setState({ openSuccessSnack: false })}
        >
          <Alert onClose={() => this.setState({ openSuccessSnack: false })} severity={snackBarSeverity}>
            <Typography>{snackText}</Typography>
          </Alert>
        </Snackbar>
        
        <Dialog onClose={() => this.setState({openDialogVerification: false})} aria-labelledby="simple-dialog-title" open={openDialogVerification}>
          <DialogTitle id="simple-dialog-title">OTP Verification - Change PIN</DialogTitle>
          
          <DialogContent>
            <p>
              A verification code has been sent to your registered phone number/email. Please enter the OTP code to proceed with changing your PIN.
            </p>
            <p>
              If you did not receive the code, you can request a new one by clicking the Resend Code button.
            </p>

            <TextField
              margin="normal"
              label={`Enter your code verification`}
              type="text"
              fullWidth
              required
              value={code_verification}
              onChange={(e) => this.setState({ code_verification: e.target.value })}
            />

            <center>
              <Countdown
                date={count_date + timer_code}
                onComplete={this.props.count} // <-- This will trigger the count function when the countdown completes.
                renderer={renderer}
              />
            </center>
          </DialogContent>

          <DialogActions>
            <Button onClick={() => this.setState({openDialogVerification: false})} color="primary">
              Cancel
            </Button>
            <Button onClick={this.onCheckCodeAndUpdate} color="primary">
              Verify
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default Page;
